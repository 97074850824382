import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import './index.css'


import SEO from "../components/seo"

const Delay = (props) => {
    return (<div className="title" style={props.style}>{props.name}</div>)
}

const ArticlePrev = (props) => {
  props.node.frontmatter.thumb.publicURL= props.node.frontmatter.thumb.publicURL || "";

  return (    
  <div className="post">
    <Link to={props.node.fields.slug} style={{textDecoration:"None"}}>
      <div className="thumb" style={{backgroundImage: `url('${props.node.frontmatter.thumb.publicURL}')`}}></div>
      
      <div className="preview"><h3>{props.node.frontmatter.title}</h3>
      <p style={{fontSize:"0.8em"}}>{props.node.frontmatter.date}</p>
      {props.node.frontmatter.description}</div>
      </Link>
    </div>
    )
}



const Main = (props) => {

  const [length,setLength] = useState(0);
  useEffect(() => {
  const timer = setTimeout(() => {
      if(length<name.length) {
      setLength(length+1);
      }
    }, 100);
    return () => clearTimeout(timer);
  }
  )


    const name  = "Henrik Tünnermann"
    const data = props.data;

    var n1,n2;
    if(length>6) {n1=6;n2=length} else {n1=length;n2=6}
    
    return (
        <>
        <SEO title="All posts" />

        <div style={{marginTop:"100px", marginLeft:"30px", marginRight:"30px",}}>

          <Delay name={name.substring(0,n1)} style={{width:"5em"}}/>
          <Delay name={name.substring(6,n2)} style={{width:"8em"}}/>

          <video autoPlay loop muted playsInline poster="poster.jpg">
              <source src="bg.mp4" type="video/mp4" />
          </video>
          <div className="topContent">
            
            <h1>Articles</h1>

            <div className="fx">
              {data.allMarkdownRemark.edges.map((edge, i)=> (<ArticlePrev node={edge.node} key={i}/>) )}
            </div>
          </div>
        </div>
    </>
    )
}

export default Main;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumb {
              publicURL
            }
          }
        }
      }
    }
  }
`